import React from 'react';
import "./earthly.css";
import {Modal, Button,Form} from 'react-bootstrap';

class ModalBox extends React.Component{
  render(){
    const {
      show,
      handleClose,
      onsubmit,
      smessage,
      emessage,
      email,
      oninputchange,
    } = this.props;
    return (
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Join Earthly</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.props.smessage !== "" &&
          <div className="alert alert-success" role="alert">
              {this.props.smessage}
            </div>
          }
          {this.props.emessage !== "" &&
          <div className="alert alert-danger" role="alert">
              {this.props.emessage}
            </div>
          }

          <Form onSubmit={onsubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={oninputchange}


                required />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <div style={{textAlign:"center"}}>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
            </div>
          </Form>
          </Modal.Body>
        </Modal>
      </div>

    )
  }
}

export default ModalBox;
